.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.clearfix{
  padding: 15px;
  margin-bottom: 10px;
}
.nohover:hover{
  color: unset !important;
}
.pending_status{
  background:yellow !important
}
.other_status{
  background:#F5F5F5 !important
}
/* a {
  color: #5D6975;
  text-decoration: underline;
} */

#logo {
  text-align: center;
  margin-bottom: 15px;
}

/* #logo img {
  width: 90px;
} */

.invoiceh1 {
  border-top: 1px solid  #5D6975;
  border-bottom: 1px solid  #5D6975;
  color: #5D6975;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: left;
  margin: 0 0 20px 0;
}

#project {
  float: left;
  margin-top: 10px;
}
#secProject {

  float: right;
  display: flex;
  margin-top: 10px;
}

#project3{
  /* float: right; */
  margin-right: 10px;
}
#project span {
  color: #5D6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

#company {
  float: right;
  text-align: right;
}

#project div,
#company div {
  white-space: normal;        
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table tr:nth-child(2n-1) td {
  /* background: #F5F5F5; */
}

table th,
table td {
  text-align: center;
}

table th {
  padding: 5px 20px;
  color: #5D6975;
  border-bottom: 1px solid #C1CED9;
  white-space: none !important;        
  font-weight: normal;
}

table .service,
table .desc {
  text-align: left;
}

table td {
  padding: 20px;
  text-align: right;
}

table td.service,
table td.desc {
  vertical-align: top;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 1.2em;
}

table td.grand {
  border-top: 1px solid #5D6975;;
}
#notices{
  margin-top: 10px;
}
#notices .notice {
  color: #5D6975;
  font-size: 1.2em;
}

.invoicefooter {
  color: #5D6975;
  width: 100%;
  height: 30px;
  bottom: 0;
  margin-top: 15px;
  border-top: 1px solid #C1CED9;
  padding: 8px 0;
  text-align: center;
}
.white-space{
  white-space: nowrap;
}

.br-headtitle{
  font-size: 24px;
}

.br-dash-icon{
  background: #54e69d !important;
  width: 60px !important;
  height: 60px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 10px;
}

.br-dash-icon i{
  color: #fff !important;
  font-size: 34px;
}
.br-card-body{
  height: 300px;
  overflow-y: auto;
}
.br-card-body::-webkit-scrollbar {
  width: 10px;
}
 
.br-card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.br-card-body::-webkit-scrollbar-thumb {
  background-color: #d2d2d282;
  border-radius: 5px;
}

.br-main-card .react-bs-container-body{
  max-height: 320px !important;
  overflow-y: auto;
}
.br-main-card .react-bs-container-body::-webkit-scrollbar-thumb {
  background-color: #e6bb4582;
  border-radius: 5px;
}
.br-main-card .react-bs-container-body::-webkit-scrollbar {
  width: 5px;
}

.br-main-card .react-bs-container-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.br-line-h{
  line-height: 1px;
}
.br-card-customer{
  min-height: 315px !important;
}
.parent-expand-foo{
  background-color:#EEF5F9 !important;
  transition: all 0,3s;
}
.br-table-card table td {
  text-align: left !important;
}
.br-table-style{
  display: flex;
  justify-content: space-between;
}

.br-table-style h5{
  font-weight: 300;
  font-size: 12px;
  
}
.br-table-style h5:first-child{
  font-weight: 400 ;
  width: 35%;
}
.br-table-style h5:last-child{
  width: 65%;
}
.table-box{
  background-color: #EEF5F9 ;
  margin: 0 0px;
  padding: 10px;
}
.br-table-card-box table td{
text-align: start !important;
font-size: 14px;
    padding: 8px;
    cursor: pointer;
}
.br-table-card .reset-expansion-style{
padding: 0;
}
.br-table-card-box table th{
  font-size: 14px ;
  white-space: nowrap ;
}
.errow--err{
  font-size: 16px;
}
.input-title{
  font-size: 14px;
}
.delivery-address{
  border-right: none !important;
}

.br-table-style h5{
  font-weight: 300;
  font-size: 12px;
  
}
.br-table-style h5:first-child{
  font-weight: 400 ;
  width: 35%;
}
.br-table-style h5:last-child{
  width: 65%;
}
.table-box{
  background-color: #EEF5F9 ;
  margin: 0 0px;
  padding: 10px;
}
.br-table-card-box table td{
text-align: start !important;
font-size: 14px;
    padding: 8px;
    cursor: pointer;
}
.br-table-card .reset-expansion-style{
padding: 0;
}
.br-table-card-box table th{
  font-size: 14px ;
  white-space: nowrap ;
}
.errow--err{
  font-size: 16px;
}
.btn.btn-danger{
  background: rgb(217, 177, 101);
  color: #fff;
  border: rgb(217, 177, 101);
}
.btn.btn-danger:hover, .btn.btn-danger:focus, .btn.btn-danger:active{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
button:focus{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
input:focus, input:active, input:hover, select:focus, input:active, select:hover{
  outline: none !important;
  box-shadow: none !important;
}
.customerDetailInvoice{
  width: 110px;
}
.customerDetailInvoice  .react-bs-table-container{
  padding: 0;
}
.customerDetailInvoice > table > th{
  display: none;
}
.customerDetailInvoice .react-bs-table-bordered{
  border: none;
  border-radius: none;
}
/* .customerDetailInvoice table thead{
  display: none;
} */
.customerDetailInvoice  .react-bs-table .table-bordered {
  border: none;
  outline: 0!important;
}
.customerDetailInvoice > .react-bs-table td {
  background: #fff !important;
  border: none;
  padding: 0;
}
.customerDetailInvoice  .pending_status, .customerDetailInvoice  .other_status{
  background: #fff !important;
  border: none !important;
}
.customerDetailInvoice .pending_status:active, .customerDetailInvoice .other_status:active {
  border: none;
  background: #fff;
}
.customerDetailInvoice .react-bs-table .table-bordered>tbody>tr>td{
  border: none;
}
.parent-expand-foo table tr:nth-child(2n-1) td {
  background: #F5F5F5;
}
.customer-detail-table table th{
  white-space: inherit;
}
.invoicetable tbody td{
  background-color: #fff !important;
}
.secProject1{
  display: flex;
}