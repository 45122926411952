.customer-footer{
    position: fixed;
    width: 100%;
    background: #f5f5f5;
    color: #000000;
    bottom: 0;
    padding: 20px 10px;
    height: 20%;
    border-top: 2px solid #8888;
}
.address-suggestion {
    position: absolute;
    background-color: #fff;
    /* position: absolute; */
    z-index: 100;
    width: 98%;
    top: 50px;
    /* width: 93%; */
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
    border-color: #777;
}
.green-text{
    color: green;
}

.search-wrapper {
    display: flex;
    align-items: center;
    padding-right: 10px;
    /* border: 2px solid #4c4948; */
    z-index: 9;
    background: #fff;
    width: 100%;
    margin-top: 5px;
    /* left: 5%; */
    /* top: 5rem; */
    /* position: absolute; */
}

.suggestions{
    /* padding: 12px 0 12px 24px; */
    display: grid;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea;
}

.suggestions:hover{
    background-color: #eee
}

.upper-text{
    font-size:14px;
    color:#777
}

.bottom-text{
    font-size:14px;
    color:#777
}


.decrement-btn{
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    text-align: center;
    background-color: #fff;
    color: black;
    position: relative;
    /* font-size: 18px; */
    font-weight: bold;
    border: 2px solid gray;
}
.increment-btn{
    user-select: none;
    width: 26px;
    height: 24px;
    display: block;
    position: relative;
    text-align: center;
    /* background-color: #C00A27; */
    background-color: #d9b165;
    color: #fff;
    /* font-size: 12px; */
    font-weight: bold;

}
.inc-icon{
    font-size: x-large;
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}
.dec-icon{
    font-size: x-large;
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}


.indexing{
    display: flex;
}

.icon-indexing{
    background: #d9b165;
    height: 20px;
    width: 20px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
}

.add-btn{
    cursor: pointer;
}
.btn-text-clr{
    color: #fff !important;
    font-weight: 500;
}
.btn-text-clr-added{
    color: #d9b165 !important;
    font-weight: 500;
}

.added{
    border: 1px solid #d9b165;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #d9b165;
    font-weight: 500;
    text-align: center;
    width: 80px;
    height: 22px;
}

.notAdded{
    /* background: #C00A27;
    border: 1px solid #C00A27; */
    background: #d9b165;
    border: #d9b165;
    color: #fff;
    font-weight: 500;
    text-align: center;
    width: 60px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lt-item{
    display: flex;
    /* width: calc(100% - 80px); */
    flex-wrap: wrap;
    justify-content: space-between;
}

.multi-addon-row{
    display: flex;
    justify-content: space-between;
}

.row-wrapper{
    display: flex;
    justify-content: space-between;
}
.online{
    display: flex;
    align-items: center;
}
.offline{
    display: flex;
    align-items: center;
}

.online-indicator{
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
}

.offline-indicator{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
}

.price-box{
    display: flex;
}
.listScroll{
    overflow-y: scroll;
    height: 500px;
}

.customer-detail-section{
    display: flex;
    flex-direction: column;
    border: 1px solid #eaeaea;
    padding: 8px;
}

.error-msg{
text-align: center;
}

.error-text{
    font-size: 19px;
    font-weight: 600;
    opacity: 0.8;
    margin-left: 1rem;
}
.customer-items-set{
    display: flex;
    gap: 15px;
    font-size: 14px;
}

.delevery-titles{
    font-size: 14px;
    white-space: break-spaces;
}
.br-box-titles{
    font-size:14px;
    margin-bottom: 0;
}
.br-box-2{
    font-size:14px;
    font-weight: 500;

   
}
.chose-option{
    font-size:14px;    
}
.white-space-main{
    white-space: nowrap; 
    margin-right: 3px;
}
.f-s-14{
    font-size:14px;
}
.daily-feeds h5{
    font-size:14px;      
}

/* New Styling for Card Issue */
.item-statics-list{
    margin-top: 10px;
}
.card-list-statics{
    margin: 10px 0;
}
.menu-item-card .card{
    margin-bottom: 10px;
}
@media screen and (min-width: 1300px) {
      /* Optional: If you want to target the specific text within h4 */
      .form-group-custom-control h4{
        max-width: 230px; /* Set your desired maximum width */
        white-space: normal; /* Allow text to wrap */
      }
}
@media screen and (max-width: 1200px) {
    .myprofile {
      font-size: 10px;
    }
}
/* @media screen and (max-width: 992px) {
    .listScroll {
        height: 100px;
    }
} */
.card .add-btn {
    padding: 10px !important;
    line-height: 20px;
    height: 54px;
}
.date .combo-choices{
    font-size: 12px;
    font-weight: 300;
}
.city-dwn{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding-left: 0;
}
.city-dwn .dropdown-item:hover{
    background-color: rgb(217, 177, 101) !important;
    color: #fff;
}
