body, html{
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    position: relative;
    overflow: auto !important;
}
/* body::-webkit-scrollbar {
  width: 0.9em;
} */

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #f3f3f35e;
    border-radius: 3px;
}
::-webkit-scrollbar-track{
  /* background-color: aqua !important; */
}
::-webkit-scrollbar-thumb {
background-color: #d9b165; 
outline: none;
}
.draggable-widget {
    width: 300px;
    height: 450px;
    margin: auto;
    background: rgb(217, 177, 101);
    color: #fff;
    text-align: center;
    line-height: 450px;
    position: absolute;
    cursor: move;
    /* left: 30%;
    right: 30%; */
}
.genesys-widget .circleIcon{
    filter: hue-rotate(180deg); /* 180deg is for inverting yellow */
    margin-top: 5px;
    width: 35px !important;
    height: auto;
    cursor: pointer;
}
body .draggable-widget{
    position: absolute ;
    top: 0% !important;
    /* left: 40%!important; */
    transform: translate(80%, 20%);
}
.widgetIconItem{
    position: relative;
    resize: horizontal;
}
.closeMark {
    position: absolute;
    top: -215px;
    right: 21px;
    font-size: 20px;
    width: 10px;
    height: 10px;
    z-index: 1000;
    cursor: pointer;
    color: rgb(151, 127, 127);
}
#pureFrame{
    border: 2px solid #d9b165;

}

.resizeable {
    /* position: absolute;
    border: 2px solid #533535;
    width: 100px;
    height: 100px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 15px;
    min-height: 15px; */
  }
  
  /* .resizer {
    position: absolute;
    background: #d9b165;
  }
  
  .resizer-r {
    cursor: col-resize;
    height: 100%;
    right: 0;
    top: 0;
    width: 5px;
  }
  
  .resizer-t {
    cursor: row-resize;
    height: 5px;
    left: 0;
    top: 0;
    width: 100%;
  }
  
  .resizer-b {
    cursor: row-resize;
    height: 5px;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .resizer-l {
    cursor: col-resize;
    height: 100%;
    left: 0;
    top: 0;
    width: 5px;
  } */
  .draggable-widget {
    cursor: grab;
  }
  .draggable-widget.dragging {
    cursor: grabbing;
  }
    